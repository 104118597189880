@import '~bootstrap/scss/bootstrap';
@import './assets/global';

a {
  text-decoration: none;
}

.accordion-button {
  outline: none;

  &:focus {
    box-shadow: none;
  }

  &:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
  }
}
