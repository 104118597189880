.button-container-fade {
  opacity: 0.4;
  transition: 0.1s;
}

.wait-button-centered {
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.wait-button-centered.show {
  opacity: 1;
  visibility: visible;
  transition: 0.1s;
}
