$stepper-color-connector: #e0e0e0;
$stepper-color-shadow: #e0e0e0;
$stepper-color-border: #d0d0d0;
$stepper-color-background: #fff;
$stepper-color-hover: rgba(black, 0.06);
$stepper-color-index: #000;
$stepper-color-success: #4caf50;
$stepper-color-error: #f44336;
$stepper-border-radius: 3px;
$stepper-circle-size: 24px;
$stepper-header-height-horizontal: 72px;
$stepper-header-height-vertical: 64px;
$stepper-padding: 24px;
$stepper-connector-min-size: 32px;
$stepper-action-height-horizontal: 64px;
$stepper-action-height-vertical: 48px;
