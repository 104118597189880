.body-class {
  overflow: hidden;
}

.bm-burger-button {
  position: absolute;
  width: 30px;
  height: 23px;
  left: 10px;
  top: 19px;
}

.bm-burger-bars {
  background: #535454;

  &:hover {
    background: black;
    outline: none;
  }
}

.sidemenu {
  .bm-cross {
    background: #888;
  }

  .bm-menu {
    background: #535454;
    padding: 1.8em 0;
    font-size: 1.15em;
    height: 70%;
  }

  h2 {
    margin: 0 auto;
    color: #b8b7ad;
    background: rgb(0 0 0 / 20%);
    box-shadow: inset 0 -1px rgb(0 0 0 / 20%);
    text-align: left;
    padding-left: 0.5em;

    &:focus {
      outline: none;
    }

    span {
      font-size: 0.5em;
      font-weight: 500;
    }
  }

  .bm-item-list {
    overflow-y: auto;
    height: calc(100% - 60px) !important;
  }

  .bm-item {
    svg {
      margin-right: 0.3em;
    }
  }

  a {
    padding: 0.3em;
    text-align: left;
    transition:
      background 0.3s,
      box-shadow 0.3s;
    box-shadow: inset 0 -1px rgb(0 0 0 / 20%);
    color: #b8b7ad;
    letter-spacing: 1px;
    font-weight: 400;

    span {
      letter-spacing: 1px;
      font-weight: 400;
    }

    &:hover,
    &:focus {
      background: #505050;
      box-shadow: inset 0 -1px rgb(0 0 0 / 0%);
      color: yellow;
      text-decoration: none;
      outline: none;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #b8b7ad;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
