.form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.footer {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  justify-content: center;
}
