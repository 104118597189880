.cardHeader {
  button {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    text-align: left;
  }
}

.externalTransferHeader {
  button::after {
    display: none;
  }
}
