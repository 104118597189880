.learner-reference-wrapper {
  padding: 40px;

  .text {
    color: #718096;
    margin-bottom: 16px;
    text-align: center;
  }

  .learner-reference-inner {
    margin: auto;
    width: 50%;

    .search-block {
      background-color: #f7f7f7;
      padding: 40px;
      position: relative;
      z-index: 1;

      .search-input {
        background: #fff;
        margin: 0;
        outline: 0;
        padding: 8px;
        width: 75%;
      }

      .search-button {
        appearance: auto;
        background-color: #fff;
        border-left: 1px solid #e2e8f0;
        color: #212529;
        padding: 8px;
        text-align: center;
        width: 25%;

        &:focus,
        &:active {
          outline: 0;
        }
      }

      &.loading {
        &::before {
          background-color: grey;
          inset: 0;
          content: '';
          display: block;
          opacity: 0.7;
          position: absolute;
          z-index: 2;
        }

        @keyframes loader-01 {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }

        &::after {
          animation: 2s loader-01 linear infinite;
          border: 0.2em solid transparent;
          border-left-color: #fff;
          border-right-color: #fff;
          border-radius: 50%;
          content: '';
          display: block;
          height: 36px;
          left: calc(50% - 18px);
          position: absolute;
          top: calc(50% - 18px);
          width: 36px;
          z-index: 2;
        }
      }
    }

    .no-data-label {
      margin-top: 30px;
      text-align: center;
    }
  }
}
