.text {
  font-size: small;
  margin: 0;
  margin-top: 0.25rem;
}

.default {
  color: black;
}

.error {
  color: var(--color-glyph-danger);
}

.success {
  color: var(--color-glyph-success);
}

.warning {
  color: var(--color-glyph-warning);
}
