.container {
  display: flex;

  &.horizontal {
    flex-direction: row;
    column-gap: 16px;
  }

  &.vertical {
    flex-direction: column;
    row-gap: 16px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  flex: 1;
}

.label {
  font-size: medium;
}
