.container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  max-width: 600px;
  width: 100%;
}

.content {
  flex: 1;
  position: relative;

  .inputWrapper {
    flex: 1;
  }
}

.input {
  width: 100%;
}
