.wrapper {
  margin: 0;

  .uploader-text {
    color: #007bff;
  }

  .uploader-text:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
