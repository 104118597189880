.container {
  padding-block: 16px;
}

.loading {
  text-align: center;
}

.error {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  row-gap: 16px;
}
