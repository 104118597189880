.tbody {
  position: relative;

  tr {
    &.even td {
      background-color: white;
    }

    &.odd td {
      background-color: #e3e3e3;
    }
  }
}
