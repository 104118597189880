.table {
  td {
    vertical-align: middle;
  }
}

.action {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.cellCodesCount {
  text-align: center;
  width: 100%;
}
