.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  table {
    position: relative;

    td {
      height: 40px;
      border: 1px solid #f3f3f3;
    }

    th {
      border: 1px solid #f3f3f3;
      text-align: center;
      background-color: #d3d3d3;
    }
  }
}
