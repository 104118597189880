.container {
  :global {
    .nav-item {
      & > button {
        background-color: transparent;
        outline: none;
        height: calc(100% + 1px);
      }
    }
  }
}
