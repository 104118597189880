.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255 255 255 / 40%);
  display: flex;
  justify-content: center;
  align-items: center;
}
