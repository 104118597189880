.header {
  background-color: black;
  color: white;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.row {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.text {
  font-weight: bolder;
  flex: 0 0 100px;
}

.footer {
  border-top: none;
}

.input {
  appearance: textfield;
  width: 100px;
  height: 40px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}
