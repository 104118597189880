.form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-bottom: 16px;
}

.row {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}

.applicationFee {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.actions {
  justify-content: flex-end;
}
