.enrollment-modal-main {
  &.hidden-modal-enrollment + .nav-tabs {
    .nav-item:first-child {
      margin-left: 0 !important;
    }
  }

  &.pending-modal-enrollment + .nav-tabs {
    .nav-item:first-child {
      margin-left: 147px !important;
    }
  }
}

.plus-placeholder {
  height: 30px;
  margin-bottom: -45px;
  margin-left: 15px;
  width: 1px;
}

.readmission-loader {
  height: 30px;
  margin-bottom: -45px;
  margin-left: 15px;
  position: relative;
  width: 30px;

  &::before {
    background-color: #fff;
    inset: 0;
    content: '';
    display: block;
    opacity: 0.7;
    position: absolute;
    z-index: 2;
  }

  @keyframes loader-01 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  &::after {
    animation: 2s loader-01 linear infinite;
    border: 0.2em solid transparent;
    border-left-color: grey;
    border-right-color: grey;
    border-radius: 50%;
    content: '';
    display: block;
    height: 36px;
    left: calc(50% - 18px);
    position: absolute;
    top: calc(50% - 18px);
    width: 36px;
    z-index: 2;
  }
}

.cohort-collapse .readmission-loader {
  margin-bottom: 0;
}

.format-error {
  color: red;
  font-size: 12px;
}

.enrollment-modal-wrapper {
  .modal-content {
    .sap-unselect {
      background: none;
      border: 0;
      color: #007bff;
      padding: 0;
      outline: 0;

      &:disabled {
        color: hsl(0deg 0% 60%);
      }
    }

    .react-datepicker__input-container input {
      max-width: 193px;
    }

    .modal-footer {
      .btn-secondary {
        background: #28a745;
        border-color: #28a745;

        &:hover,
        &:active {
          background: #218838;
          border-color: #218838;
        }
      }

      button:disabled {
        cursor: not-allowed;
      }

      button.btn-secondary:disabled {
        &:hover,
        &:active {
          background: #28a745;
          border-color: #28a745;
        }
      }

      .btn-danger {
        margin-right: auto;
      }
    }
  }
}

.error-message {
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  background: #f4cccc;
  border: solid 1px black;
  margin-bottom: 5px;
}

.sap-file-area {
  .sap-link {
    margin-left: -3px;
  }

  .close {
    border: 0;
    outline: 0;
  }
}
