.field {
  display: flex;
  flex-direction: row;
  column-gap: 8px;

  &.reverse {
    flex-direction: row-reverse;
    justify-self: flex-start;
  }

  .label {
    margin: 0;
    display: grid;
    grid-template-columns: 10px 1fr;

    .asterisk {
      color: red;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    .description {
      font-size: small;
      color: grey;
      min-height: 1rem;
      min-width: 1px;
      line-height: 1rem;
    }
  }

  .label,
  .content {
    padding-top: 4px;
  }
}

.partnerlogopreview {
  width: 350px;
}
