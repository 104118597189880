.container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 16px;
  background-color: #d3d3d3;
  font-size: smaller;
}

.row {
  display: flex;
  flex-direction: row;
  column-gap: 32px;
  align-items: center;
}

.field {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;

  .label {
    font-weight: bolder;
  }
}
