@import '../vars';

.stepper {
  .stepper__action {
    &:not([disabled]) {
      cursor: pointer;
    }

    + .stepper__action {
      margin: 0;
      margin-right: ($stepper-padding / 2);
    }

    &.stepper__action--right {
      margin-left: auto;

      + .stepper__action--right {
        margin: 0;
        margin-left: ($stepper-padding / 2);
      }
    }
  }
}
