.success {
  --bs-tooltip-bg: var(--color-glyph-success);
}

.danger {
  --bs-tooltip-bg: var(--color-glyph-danger);
}

.warning {
  --bs-tooltip-bg: var(--color-glyph-warning);
}

.info {
  --bs-tooltip-bg: var(--color-glyph-info);
}
