.title {
  background-color: black;
  color: white;
  padding-inline: 16px;
  padding-block: 8px;
}

.content {
  padding: 16px;
}
