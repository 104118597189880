.content {
  flex: 1;
}

.select {
  &.error {
    border-color: var(--color-glyph-danger) !important;

    &.focused {
      box-shadow: 0 0 0 1px var(--color-glyph-danger);
    }
  }
}
