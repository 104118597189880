.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-block: 16px;

  .item {
    font-size: medium;
  }
}
