.title {
  font-size: medium;
  margin-top: -10px;
  padding-inline: 8px;
  margin-left: 16px;
  margin-bottom: 0;
  background-color: white;
  width: fit-content;
}

.body {
  padding-block: 8px;
  padding-inline: 12px;
}
