.placeholder {
  text-align: center;
  padding: 16px;
  font-size: small;
  width: 100%;
  color: grey;
}

.error {
  color: red;
}

.table {
  margin: 0;
}
