.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.title {
  flex: 1;
}
