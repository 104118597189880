.container {
  display: flex;
  align-items: center;
  position: relative;
  column-gap: 1rem;

  .switch {
    background-color: #e2e2e2;
    border-radius: 10px;
    width: 2.25rem;
    height: 1rem;
    position: relative;
    transition: all 0.4s ease;
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      top: -0.25rem;
      left: -0.75rem;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      transition: all 0.4s ease;
      transform: translateX(0);
      background-color: #b2b2b2;
    }
  }

  .input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;

    &:checked {
      & + .switch {
        background-color: rgba(purple, 0.2);

        &::before {
          background-color: purple;
          transform: translateX(2.25rem);
        }
      }
    }
  }

  .label {
    font-size: small;
    margin-bottom: 0;
  }
}
